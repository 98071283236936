<template>
<div>
  <Row :gutter="10">
    <Col span="24" style="text-align: right">
      <Button type="primary" @click="add()">添加</Button>
    </Col>
  </Row>
  <Row style="margin-top: 15px">
    <Col span="24">
      <Table row-key="id" stripe border :columns="columns" :data="list">
        <template slot="action" slot-scope="{row}" >
          <template v-if="!(row.children && row.children.length > 0)">
            <Button style="margin-right: 15px" type="primary" @click="callApiModal(row.id)" >API权限</Button>
            <Button style="margin-right: 15px" type="success" @click="callMenuModal(row.id)">菜单和按钮权限</Button>
          </template>
          <Button style="margin-right: 15px" type="warning" @click="mod(row.id)">修改</Button>
          <Button type="error" @click="del(row.id)">删除</Button>
        </template>
      </Table>
    </Col>
  </Row>

  <Modal v-model="modal.apiModal" title="选择API权限" width="1200">
    <Table row-key="id" stripe border :columns="apiColumns" :data="apiList">
      <template slot="action" slot-scope="{row}">
        <CheckboxGroup v-model="row.method" @on-change="handleApiChange(row.permission_mark, $event)" v-if="!(row.children && row.children.length > 0)">
          <Checkbox label="GET" border>获取</Checkbox>
          <Checkbox label="POST" border>新增</Checkbox>
          <Checkbox label="PUT" border>全部修改</Checkbox>
          <Checkbox label="PATCH" border>部分修改</Checkbox>
          <Checkbox label="DELETE" border>删除</Checkbox>
        </CheckboxGroup>
        <span v-else></span>
      </template>
    </Table>
    <div slot="footer">
      <Button type="primary" size="large" @click="modal.apiModal=false">关闭</Button>
    </div>
  </Modal>

  <Modal v-model="modal.menuModal" title="选择菜单和按钮权限" width="1200">
    <Table row-key="id" stripe border :columns="menuColumns" :data="menuList">
      <template slot="action" slot-scope="{row,index}">
        <Checkbox v-model="row.has" @on-change="handleMenuChange(index,row.permission_mark,row.permission_type, $event)"></Checkbox>
      </template>
    </Table>
    <div slot="footer">
      <Button type="primary" size="large" @click="modal.menuModal=false">关闭</Button>
    </div>
  </Modal>
</div>
</template>

<script>
import api from "../../api/setup";

export default {
  name: "Roles",
  data: function(){
    return {
      timer: null,
      temp: {
        currentRoleId: '',
      },
      columns: [
        {title: 'ID', key: 'id', width: 80},
        {title: '名称', key: 'label', tree: true},
        {title: '说明', key: 'role_intro'},
        {title: '操作', slot: 'action', width: 420}
      ],
      list: [],
      modal: {
        apiModal: false,
        menuModal: false
      },
      apiColumns: [
        {title: '名称', key: 'label', tree: true},
        {title: '标识', key: 'permission_mark'},
        {title: '简介', key: 'permission_intro'},
        {title: '选择', slot: 'action', width: 580}
      ],
      apiList: [],
      menuColumns: [
        {title: '名称', key: 'label', tree: true},
        {title: '标识', key: 'permission_mark'},
        {title: '简介', key: 'permission_intro'},
        {title: '类型', key: 'permission_type', width: 100},
        {title: '选择', slot: 'action', width: 80}
      ],
      menuList: []
    }
  },
  methods: {
    handleApiChange: function(mark,e){
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$http.patch(api.setApiPermission, {rid: this.temp.currentRoleId, mark: mark, method: e, type: 'api'})
      }, 500)
    },
    callApiModal: function(rid){
      this.temp.currentRoleId = rid;
      this.$http.get(api.getPermissionsForRole, {params: {type: 'api', rid: rid}}).then(response => {
        if(response.data.success) {
          this.apiList = response.data.res;
          this.modal.apiModal = true
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    },
    callMenuModal: function(rid){
      this.temp.currentRoleId = rid;
      this.$http.get(api.getPermissionsForRole, {params: {type: 'menu', rid: rid}}).then(response => {
        if(response.data.success) {
          this.menuList = response.data.res;
          this.modal.menuModal = true
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    },
    add: function(){
      this.$router.push('/setup/roles/add')
    },
    mod: function(id){
      this.$router.push({path: '/setup/roles/mod', query: {id:id}});
    },
    del: function(id){
      this.$Modal.confirm({
        content: '<p>是否确认删除该项？该项的子项也将被删除。</p>',
        onOk: () => {
          this.$http.delete(api.delRole, {data: {id:id}}).then(response =>{
            if(response.data.success) {
              this.getList();
              this.$Message.success('删除成功');
            } else {
              this.$Message.error(response.data.err);
              console.log(response)
            }
          })
        },
      });
    },
    getList: function(){
      this.$http.get(api.getRoles).then(response => {
        if(response.data.success) {
          this.list = response.data.res;
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    },
    handleMenuChange: function(index, mark, type, e){
      // console.log(index, id,type, e)
      this.$http.patch(api.setMenuPermission, {rid: this.temp.currentRoleId, mark: mark, type: type, method: e})
}
  },
  mounted() {
    this.getList();
  }
}
</script>

<style scoped>

</style>