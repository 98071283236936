var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Row',{attrs:{"gutter":10}},[_c('Col',{staticStyle:{"text-align":"right"},attrs:{"span":"24"}},[_c('Button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.add()}}},[_vm._v("添加")])],1)],1),_c('Row',{staticStyle:{"margin-top":"15px"}},[_c('Col',{attrs:{"span":"24"}},[_c('Table',{attrs:{"row-key":"id","stripe":"","border":"","columns":_vm.columns,"data":_vm.list},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var row = ref.row;
return [(!(row.children && row.children.length > 0))?[_c('Button',{staticStyle:{"margin-right":"15px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.callApiModal(row.id)}}},[_vm._v("API权限")]),_c('Button',{staticStyle:{"margin-right":"15px"},attrs:{"type":"success"},on:{"click":function($event){return _vm.callMenuModal(row.id)}}},[_vm._v("菜单和按钮权限")])]:_vm._e(),_c('Button',{staticStyle:{"margin-right":"15px"},attrs:{"type":"warning"},on:{"click":function($event){return _vm.mod(row.id)}}},[_vm._v("修改")]),_c('Button',{attrs:{"type":"error"},on:{"click":function($event){return _vm.del(row.id)}}},[_vm._v("删除")])]}}])})],1)],1),_c('Modal',{attrs:{"title":"选择API权限","width":"1200"},model:{value:(_vm.modal.apiModal),callback:function ($$v) {_vm.$set(_vm.modal, "apiModal", $$v)},expression:"modal.apiModal"}},[_c('Table',{attrs:{"row-key":"id","stripe":"","border":"","columns":_vm.apiColumns,"data":_vm.apiList},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var row = ref.row;
return [(!(row.children && row.children.length > 0))?_c('CheckboxGroup',{on:{"on-change":function($event){return _vm.handleApiChange(row.permission_mark, $event)}},model:{value:(row.method),callback:function ($$v) {_vm.$set(row, "method", $$v)},expression:"row.method"}},[_c('Checkbox',{attrs:{"label":"GET","border":""}},[_vm._v("获取")]),_c('Checkbox',{attrs:{"label":"POST","border":""}},[_vm._v("新增")]),_c('Checkbox',{attrs:{"label":"PUT","border":""}},[_vm._v("全部修改")]),_c('Checkbox',{attrs:{"label":"PATCH","border":""}},[_vm._v("部分修改")]),_c('Checkbox',{attrs:{"label":"DELETE","border":""}},[_vm._v("删除")])],1):_c('span')]}}])}),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{attrs:{"type":"primary","size":"large"},on:{"click":function($event){_vm.modal.apiModal=false}}},[_vm._v("关闭")])],1)],1),_c('Modal',{attrs:{"title":"选择菜单和按钮权限","width":"1200"},model:{value:(_vm.modal.menuModal),callback:function ($$v) {_vm.$set(_vm.modal, "menuModal", $$v)},expression:"modal.menuModal"}},[_c('Table',{attrs:{"row-key":"id","stripe":"","border":"","columns":_vm.menuColumns,"data":_vm.menuList},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('Checkbox',{on:{"on-change":function($event){return _vm.handleMenuChange(index,row.permission_mark,row.permission_type, $event)}},model:{value:(row.has),callback:function ($$v) {_vm.$set(row, "has", $$v)},expression:"row.has"}})]}}])}),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{attrs:{"type":"primary","size":"large"},on:{"click":function($event){_vm.modal.menuModal=false}}},[_vm._v("关闭")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }